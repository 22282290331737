import { useState } from "react";
import heart1 from "./assets/heart.gif";
import heart2 from "./assets/heart2.gif";
import sunflower from "./assets/sunflower.gif";
import anniversary from "./assets/anniversary.gif";
import fireworks from "./assets/fireworks.gif";
import photo_1 from "./assets/photos/photo_1.jpg";
import photo_2 from "./assets/photos/photo_2.jpg";
import photo_3 from "./assets/photos/photo_3.jpg";
import photo_4 from "./assets/photos/photo_4.jpg";
import tulip_gif from "./assets/tulip.gif";
import "./App.css";
import moment from "moment";
import { SweetMessage } from "./components/SweetMessage";
import Countdown from "./components/Countdown";
import { YoutubeEmbed } from "./components/YoutubeEmbed";
import { UsPicture } from "./components/UsPicture";

function App() {
  const gifOptions = [heart1, heart2, sunflower];
  const [gif, setGif] = useState(
    gifOptions[Math.floor((Math.random() * 100) % gifOptions.length)]
  );
  const sweetMessages = [
    "Falling asleep and waking up next to you is one of my favorite things in this world",
    "I can't wait to give you another hug",
    "*Send you 8 billion back rubs*",
    "You fill my life with sunshine and joy that only you can bring me",
    "Every time I see you my day gets brighter",
    "I love you",
    "You are beautiful",
    "FAT HAMMY",
    "You are wonderful",
    "You are a perfect 10",
    "Every day with you is a dream",
    "I can't wait to see you again",
    "I'm very happy to be in this life with you",
    "I'm so happy when I am with you",
    "Being your boyfriend is the greatest thing to ever happen to me",
    "I love treating you like a princess <3",
    "<3 <3 <3 <3 <3 <3 <3 <3 <3 <3",
    "Honey bearrrrrrrrrrrrr",
    "You're cooking and baking is delicous",
    "I enjoy every moment I spend with you",
    "Whatever difficult thing is going on in your life right now sweetie, you can do it!!",
    "No star will ever brighten up my life as much as you do",
    "I support you forever and always",
    "I love you my soap",
  ];

  const today = moment().format("dddd");
  const date = moment();
  const targetDate = date.isBefore(`${date.year()}-10-2`, "day")
    ? new Date(date.year(), 9, 2)
    : new Date(date.year() + 1, 9, 2);

  const [currMessages, setCurrMessages] = useState<string[]>([]);

  const handleRemove = (message: string) => {
    setCurrMessages((prev) => prev.filter((m) => m !== message));
  };

  return (
    <div className="App">
      {date.isSame(`${date.year()}-10-2`, "day") ? (
        <div className="aniv">{"Happy Anniversary honey bear! <3 <3"}</div>
      ) : (
        <div className="aniv">
          <Countdown targetDate={targetDate}></Countdown>
        </div>
      )}
      <div className="middle-div">
        <YoutubeEmbed></YoutubeEmbed>
        <div>
          {
            <img
              src={
                date.isSame(`${date.year()}-10-2`, "day")
                  ? fireworks
                  : tulip_gif
              }
              alt="logo"
            />
          }

          <img
            src={date.isSame(`${date.year()}-10-2`, "day") ? anniversary : gif}
            alt="logo"
          />
          {
            <img
              src={
                date.isSame(`${date.year()}-10-2`, "day")
                  ? fireworks
                  : tulip_gif
              }
              alt="logo"
            />
          }
        </div>
        <UsPicture photoList={[photo_1, photo_2, photo_3, photo_4]}></UsPicture>
      </div>

      <p>{`Hi Linh! I love you, I hope you are having a good ${today}`}</p>
      <button
        className="nice-things-button"
        onClick={() => {
          const newMessage =
            sweetMessages[Math.floor(Math.random() * sweetMessages.length)];
          setCurrMessages((prev) => [...prev, newMessage]);
        }}
      >
        Click me for something sweet!
      </button>
      {currMessages.map((message, index) => (
        <SweetMessage
          key={index}
          text={message}
          onRemove={() => handleRemove(message)}
        />
      ))}
      <p>You're amazing my cute, smart, hot, funny asian woman</p>
    </div>
  );
}

export default App;
