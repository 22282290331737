import { useState } from "react";

export function YoutubeEmbed() {
  function getRandomInt(min: number, max: number) {
    const minCeiled = Math.ceil(min);
    const maxFloored = Math.floor(max);
    return Math.floor(Math.random() * (maxFloored - minCeiled) + minCeiled); // The maximum is exclusive and the minimum is inclusive
  }
  const youtubeUrls = [
    "https://www.youtube.com/embed/w-FkV0EM_CU",
    "https://www.youtube.com/embed/b5Zay_Hd_7Q",
    "https://www.youtube.com/embed/7IID5YLPg7w",
    "https://www.youtube.com/embed/8zdg-pDF10g",
    "https://www.youtube.com/embed/eVli-tstM5E",
    "https://www.youtube.com/embed/2JgvVfOfoWI",
    "https://www.youtube.com/embed/eVNNfmr_vWI",
    "https://www.youtube.com/embed/NL9Pa4cFGOg",
  ];

  const [currUrl, setCurrUrl] = useState<string>(
    youtubeUrls[getRandomInt(0, youtubeUrls.length - 1)]
  );

  return (
    <div className="youtubeEmbed">
      <p style={{ margin: "0px" }}>How I feel about you</p>
      <iframe
        width="400"
        height="500"
        src={currUrl}
        title="YouTube video player"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        referrerPolicy="strict-origin-when-cross-origin"
      ></iframe>
    </div>
  );
}
